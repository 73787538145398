<template>
    <BaseLayout head-title="Головна | КапКульт" main-margin-top="0">
        <Container class="pt-24 mb-8 md:mb-10">
            <div class="md:grid md:grid-cols-2 md:gap-5 md:h-[30rem]">
                <div class="gap-5 flex flex-col">
                    <div class="p-8 px-8 h-full flex items-center bg-gradient-to-t from-[#E4D9C6] to-[#E6DECF] relative">
                        <img fetchpriority="high" src="@/../images/home/coffee-bro.webp" alt="background image" class="absolute inset-0 w-full h-full object-contain object-right z-0">

                        <div class="relative font-heading select-none cursor-default">
                            <p class="inline-block bg-green-500/80 p-1 mb-1">привіт</p><br>
                            <p class="inline-block bg-green-500/80 p-1">ми твій<br>кавовий бро</p>
                        </div>
                    </div>

                    <div class="grid grid-cols-2 gap-5 font-bold">
                        <Link v-for="mostUsedLink in mostUsedLinks" :href="mostUsedLink.url">
                            <div
                                :class="mostUsedLink.accent ? 'bg-green-500' : ''"
                                class="border-2 border-black p-3 md:p-4 flex items-center hover:border-indigo-500 hover:text-indigo-500 text-sm md:text-base"
                            >
                                <component :is="mostUsedLink.icon" class="h-5 w-5 md:h-6 md:w-6 mr-3 flex-none"/>
                                {{ mostUsedLink.text }}
                            </div>
                        </Link>
                    </div>
                </div>

                <div class="md:h-[30rem] mt-5 md:mt-0">
                    <swiper
                        class="h-32 sm:h-40 md:h-full"
                        space-between="20"
                        :breakpoints="generalBreakpoints"
                        :autoplay="autoplay"
                        :loop="true"
                        :lazy="true"
                        :modules="modules"
                    >
                        <swiper-slide class="border-black border-2 hover:border-indigo-500">
                            <Link :href="route('categories.show', {category: 'coffee'})">
                                <div class="w-full h-full">
                                    <img fetchpriority="high" src="@/../images/home/banner1.webp" class="object-cover object-center h-full w-full">
                                </div>
                            </Link>
                        </swiper-slide>
                        <swiper-slide class="border-black border-2 hover:border-indigo-500">
                            <Link :href="route('categories.show', {category: 'coffee'})">
                                <div class="w-full h-full">
                                    <img src="@/../images/home/banner2.webp" class="object-cover object-center h-full w-full">
                                </div>
                            </Link>
                        </swiper-slide>
                        <swiper-slide class="border-black border-2 hover:border-indigo-500">
                            <Link :href="route('categories.show', {category: 'coffee'})">
                                <div class="w-full h-full">
                                    <img src="@/../images/home/banner3.webp" class="object-cover object-center h-full w-full">
                                </div>
                            </Link>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </Container>

        <div class="relative top-0 bg-white" id="products">
            <section class="mb-5 md:mb-16">
                <Container>
                    <h4 class="md:text-xl font-heading">Зерно</h4>
                    <div class="min-h-[4rem] max-h-28 flex justify-between items-center">
                        <div class="flex overflow-auto -mb-5 space-x-4 pt-1 pl-1">
                            <SmallButton
                                v-for="(filter, index) in productFilters"
                                :text="filter.title"
                                :color="activeFilter === index ? 'black' : 'white'"
                                :text-color="activeFilter === index ? 'white' : 'black'"
                                :border="activeFilter === index ? 'white' : 'black'"
                                :loading="this.productForm.processing"
                                class="cursor-pointer mb-5 min-w-24 md:min-w-32 lowercase"
                                @click="selectFilter(index)"
                            />
                        </div>

                        <Link :href="activeProductsUrl" class="hover:text-indigo-600 items-center hidden lg:flex font-bold">
                            Дивитись усі
                            <Chevron class="ml-3 h-3"/>
                        </Link>
                    </div>

                    <ProductsHorizontalList :products="products"/>

                    <div class="flex justify-center lg:hidden mt-5">
                        <Link :href="activeProductsUrl" class="w-56">
                            <PrimaryButton color="white" text-color="black" hover-color="green">
                                Дивитись усі
                            </PrimaryButton>
                        </Link>
                    </div>

                    <ViewedProducts/>
                </Container>
            </section>

            <div class="h-10 bg-divider bg-contain"/>

            <section class="pt-8">
                <Container :no-padding="true">
                    <h2 class="text-xl font-heading mb-8 md:mb-14 text-center p-3">Яке обрати зерно?</h2>

                    <div class="flex flex-wrap">
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{
                                country: 'braziliia,indiia,gonduras,meksika,peru',
                                processing: 'naturalna,musonna,dekaf'
                            }"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">з гірчинкою</p>
                                    <p class="text-sm">Класика міцного смаку кави.</p>
                                </div>
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose1.webp" alt=""/>
                                </div>
                            </div>
                        </Link>
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{
                                country: 'keniia,kolumbiia,burundi,kosta-rika',
                                processing: 'anaerobna,mita,naturalna-laktik'
                            }"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose2.png" alt=""/>
                                </div>
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">з кислинкою</p>
                                    <p class="text-sm">Подобаються яскраві смаки?</p>
                                </div>
                            </div>
                        </Link>
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{country: 'efiopiia,ruanda,salvador', 'grade-from': '85'}"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">збалансоване</p>
                                    <p class="text-sm">Якщо можливо знайти баланс, він точно тут.</p>
                                </div>
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose3.png" alt=""/>
                                </div>
                            </div>
                        </Link>
                        <Link
                            :href="coffeeCategoryUrl"
                            :data="{'grade-from': '86'}"
                            class="w-full md:w-1/2 hover:bg-gray-100 hover:text-indigo-500"
                        >
                            <div class="flex items-center">
                                <div class="w-1/3 md:w-1/2">
                                    <img class="w-full h-32 md:h-48 object-cover object-center" src="@/../images/home/choose4.png" alt=""/>
                                </div>
                                <div class="w-2/3 md:w-1/2 p-5">
                                    <p class="font-heading uppercase mb-3">щось цікаве</p>
                                    <p class="text-sm">Дещо більше ніж просто кава.</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </Container>
            </section>

            <div class="h-10 bg-divider bg-contain"/>

            <section class="py-8 md:py-16 partners" v-if="brands.length">
                <Container>
                    <h2 class="md:text-xl font-heading mb-14 text-center">Бренди на CupCult</h2>

                    <swiper
                        :loop="true"
                        :lazy="true"
                        :spaceBetween="30"
                        :centeredSlides="true"
                        :autoplay="autoplay"
                        :breakpoints="breakpoints"
                        :modules="modules"
                    >
                        <swiper-slide
                            v-for="brand in $page.props.brands"
                            class="px-4 mb-8"
                        >
                            <div class="flex items-center justify-center h-20 mb-5">
                                <Link :href="route('brands.show', {brand})" :aria-label="`Бренд ${brand.title}`">
                                    <img
                                        class="object-contain transition-all duration-300 h-14 hover:h-20"
                                        :src="brand.logo"
                                        loading="lazy"
                                        :alt="`Бренд ${brand.title}`"
                                    >
                                    <div class="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
                                </Link>
                            </div>
                        </swiper-slide>
                    </swiper>

                    <div class="flex justify-center">
                        <div class="w-64">
                            <Link :href="route('brands.index')">
                                <PrimaryButton color="white" text-color="black" hover-color="green">
                                    Усі бренди
                                </PrimaryButton>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>

            <section class="bg-gray-100 border-t-2 border-black">
                <Container :no-padding="true">
                    <div class="md:flex md:h-96">
                        <div class="h-full flex md:w-1/2 items-center px-4 py-10">
                            <div>
                                <p class="text-xl font-heading uppercase mb-5">Мапа спешелті закладів</p>
                                <p>Хочеш швидко знаходити заклади поруч? Користуйся нашою мапою закладів.</p>
                            </div>
                        </div>
                        <div
                            class="h-60 md:h-full flex items-center justify-center md:w-1/2 bg-[url('@/../images/home/map.webp')] bg-cover">
                            <Link :href="route('map')" class="w-40">
                                <PrimaryButton class="max-w-40">
                                    <Map class="inline h-5 translate-y-"/>
                                    Мапа
                                </PrimaryButton>
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import 'swiper/css';

import {Link, useForm} from '@inertiajs/vue3';
import BaseLayout from "@/Layouts/BaseLayout.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import ResponsiveImage from "@/Components/ResponsiveImage.vue";
import Badge from "@/Components/Badge.vue";
import Container from "@/Components/Base/Container.vue";
import Chevron from "@/Components/Svg/Chevron.vue";
import SmallButton from "@/Components/Buttons/SmallButton.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Autoplay} from "swiper/modules";
import Map from "@/Components/Svg/Map.vue";
import Zap from "@/Components/Svg/Zap.vue";
import Gift from "@/Components/Svg/Gift.vue";
import Camera from "@/Components/Svg/Camera.vue";
import ProductsHorizontalList from "@/Partials/Products/ProductsHorizontalList.vue";
import ViewedProducts from "@/Partials/Products/ViewedProducts.vue";

export default {
    components: {
        ViewedProducts,
        ProductsHorizontalList,
        Map,
        SmallButton,
        Chevron,
        Container,
        Badge,
        BaseLayout,
        Link,
        PrimaryButton,
        ResponsiveImage,
        Swiper,
        SwiperSlide,
        Zap,
        Gift,
        Camera,
    },
    props: {
        brands: Array,
        products: Array,
        filters: Array,
        certificateSlug: String
    },
    data() {
        const filters = []

        this.filters.forEach(parameter => parameter.options.forEach(option => {
            const parameterQuery = {}
            parameterQuery[parameter.slug] = option.slug

            filters.push({
                title: option.title,
                parameterQuery,
                url: this.route('categories.show', {category: 'coffee', ...parameterQuery})
            })
        }))

        const activeProductsUrl = this.route('categories.show', {category: 'coffee'})

        return {
            activeItem: 'coffee',
            activeFilter: 0,
            coffeeCategoryUrl: activeProductsUrl,
            activeProductsUrl,
            productFilters: [
                {
                    title: 'Новинки',
                    url: activeProductsUrl
                },
                ...filters
            ],
            productForm: useForm({}),
            generalBreakpoints: {
                320: {
                    slidesPerView: 1,
                    direction: 'horizontal'
                },
                '768': {
                    slidesPerView: 3,
                    direction: 'vertical'
                },
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                '640': {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 6,
                    spaceBetween: 50,
                },
            },
            modules: [Autoplay],
            autoplay: {
                delay: 2500,
                pauseOnMouseEnter: true
            },
            mostUsedLinks: [
                {
                    url: this.route('categories.show', {category: 'coffee'}),
                    icon: 'Map',
                    text: 'Кава'
                },
                {
                    url: this.route('categories.show', {category: 'drips'}),
                    icon: 'Zap',
                    text: 'Дріпи'
                },
                {
                    url: this.route('categories.show', {category: 'equipment'}),
                    icon: 'Camera',
                    text: 'Аксесуари'
                },
                {
                    url: this.route('products.show', [this.certificateSlug]),
                    icon: 'Gift',
                    text: 'Сертифікати',
                    accent: true
                }
            ]
        }
    },
    methods: {
        selectFilter(index) {
            window.scrollTo({
                top: document.getElementById('products').offsetTop - 80,
                behavior: "smooth",
            });

            this.activeFilter = index
            this.activeProductsUrl = this.productFilters[index].url

            this.productForm.get(this.route('home', this.productFilters[index].parameterQuery), {
                only: ['products'],
                replace: true,
                preserveState: true,
                preserveScroll: true,
            })
        },
        addJsonSchema(text) {
            const script = document.createElement('script');
            script.setAttribute('type', 'application/ld+json');
            script.textContent = text;
            document.head.appendChild(script);
        }
    },
    mounted() {
        this.addJsonSchema(`{
          "@context" : "https://schema.org",
          "@type" : "WebSite",
          "url" : "https://cupcult.co",
          "name": "CupCult",
          "alternateName": "КапКульт",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://cupcult.co/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        }`)

        this.addJsonSchema(`{
          "@context": "https://schema.org",
          "@type": "OnlineStore",
          "url": "https://cupcult.co",
          "sameAs": ["https://www.instagram.com/cup.cult/", "https://t.me/cupcult_helpbot?start=start"],
          "logo": "https://cupcult.co/images/logo.jpg",
          "name": "CupCult",
          "alternateName": "КапКульт",
          "description": "Онлайн маркетплейс кави пропонує зерно, дріпи, обладнання для приготування смачної кави.",
          "email": "cupcult.official@gmail.com",
          "location": "Київ",
          "numberOfEmployees": {
              "@type": "QuantitativeValue",
              "minValue": 1,
              "maxValue": 10
          },
          "contactPoint": {
            "contactType": "Customer Service",
            "email": "cupcult.official@gmail.com"
          },
          "foundingDate": "2024-03-25",
          "founder": {
            "@type": "Person",
            "givenName": "Микола",
            "familyName": "Сірик"
          }
        }`)
    }
}
</script>

<style scoped>
@reference "../../../css/app.css";

.partners .swiper-slide-active {
    @apply border-b-8 border-indigo-500
}
.partners .swiper-slide-active img {
    @apply h-16
}
</style>
